import { Strategy, StrategyAbi } from "../index";
import React from "react";
import MetaMorphoAbi from "../abi/metaMorphoAbi.json";
import { providers, Signer } from "ethers";
import { Abi } from "abitype";
import { Maybe } from "true-myth";
import { AppConfigContext } from "../../context";
import { useContract } from "wagmi";

export const abiMap: Record<Strategy, Abi> = {
    [Strategy.MetaMorpho]: MetaMorphoAbi as Abi,
    [Strategy.MetaMorphoTBTC]: MetaMorphoAbi as Abi,
};

export const useStrategyContract = <T extends Strategy>(
    strategy: T,
    signerOrProvider?: Signer | providers.Provider,
): StrategyAbi<T> => {
    const { strategies } = React.useContext(AppConfigContext);
    const address: string = strategies[strategy].address;
    const abi = abiMap[strategy];

    return Maybe.of(useContract({ address, abi, signerOrProvider })).unwrapOrElse(() => {
        throw new Error(`Failed to create contract for strategy: ${strategies[strategy].name}`);
    }) as StrategyAbi<T>;
};
