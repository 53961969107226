import {
  ChakraProvider,
  Container,
  Box,
} from "@chakra-ui/react"
import { RainbowKitProvider, lightTheme, Theme } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from "wagmi";
import { client } from "./wagmi";
import { Header } from "./components";
import { theme } from "./theme";
import { chains } from "./wagmi";
import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";
import { Strategy } from "./contracts";
import { MetaMorphoPage } from "./pages/MetaMorphoPage";
import merge from 'lodash.merge';
import { ThemeColor } from "./theme/palette";

import '@rainbow-me/rainbowkit/styles.css';
import "./main.css";

export function App() {
  const getStrategyPage = (strategy: Strategy) => {
    switch (strategy) {
      case Strategy.MetaMorphoTBTC:
        return (
          <MetaMorphoPage<Strategy.MetaMorphoTBTC>
            strategy={Strategy.MetaMorphoTBTC}
            strategySymbol="tBTC"
          />
        );
      case Strategy.MetaMorpho:
      default:
        return (
          <MetaMorphoPage<Strategy.MetaMorpho>
            strategy={Strategy.MetaMorpho}
            strategySymbol="USDC"
          />
        );
    }
  };

  const rainbowTheme = merge(lightTheme(), {
    colors: {
      accentColor: ThemeColor.CRYPTO_BORG,
      accentColorForeground: ThemeColor.CRYPTO_BORG,
    },
    fonts: {
      body: "TTCommons"
    }
  } as Theme)

  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <WagmiConfig client={client}>
          <RainbowKitProvider chains={chains} theme={rainbowTheme}>
            <Container
              display={"flex"}
              flexDirection={"column"}
              minH={"100vh"}
              maxW={"container.xl"}
              paddingX={{ base: "1.8rem", sm: "2.8rem", md: "3.8rem", lg: "4.2rem", xl: "4.8rem" }}
            >
              <Header />
              <Box as={"main"} flex={1}>
                <Routes>
                  <Route index element={getStrategyPage(Strategy.MetaMorpho)} />
                  <Route path="/tBTC" element={getStrategyPage(Strategy.MetaMorphoTBTC)} />
                  <Route path={"*"} element={<Navigate to="/" replace />} />
                </Routes>
              </Box>
            </Container>
          </RainbowKitProvider>
        </WagmiConfig>
      </ChakraProvider>
    </BrowserRouter>
  )
}