import { IconName } from "../uikit/icon";
import { ThemeColor } from "./palette";

export type AssetSymbol =
    | "USDC"
    | "sbMorphoUSDC"
    | "tBTC"
    | "sbMorphotBTC";

export interface Asset {
    name: string;
    displaySymbol: string;
    icon: IconName;
    color: ThemeColor;
    minimumFractionDigits: number;
    maximumFractionDigits: number;
}

export const assets: Record<AssetSymbol, Asset> = {
    USDC: {
        name: "USD Coin",
        displaySymbol: "USDC",
        icon: "crypto.usdc",
        color: ThemeColor.CRYPTO_USDC,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    },
    sbMorphoUSDC: {
        name: "SwissBorg Morpho USDC",
        displaySymbol: "sbMorphoUSDC",
        icon: "crypto.sbmorphousdc",
        color: ThemeColor.CRYPTO_MORPHO,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    tBTC: {
        name: "tBTC",
        displaySymbol: "tBTC",
        icon: "crypto.tbtc",
        color: ThemeColor.CRYPTO_TBTC,
        minimumFractionDigits: 0,
        maximumFractionDigits: 8,
    },
    sbMorphotBTC: {
        name: "SwissBorg Morpho tBTC",
        displaySymbol: "sbMorphotBTC",
        icon: "crypto.sbmorphousdc",
        color: ThemeColor.CRYPTO_MORPHO,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
};
