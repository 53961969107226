import { HStack, Table, TableContainer, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import React from "react";
import { AppConfigContext } from "../../context";
import { StrategyProperties } from "../../strategies.config";
import { ThemeColor } from "../../theme/palette";
import { Card, CardProps } from "../../uikit";
import { Icon } from "../../uikit/icon";
import { formatAddress } from "../../utils/format";
import { Maybe } from "true-myth";
import { Link } from "../Link";
import { Strategy } from "../../contracts";

interface Props<T extends Strategy> extends CardProps {
    strategy: T;
    rewardsData: Maybe<number>;
}

export const StrategyExtras = <T extends Strategy>({ strategy, rewardsData, ...props }: Props<T>) => {
    const { strategies } = React.useContext(AppConfigContext);

    const getContractHref = ({ address, chain }: StrategyProperties): string | null =>
        Maybe.of(chain.explorerUrl).mapOr(null, url => `${url}address/${address}`);

    const renderExtra = (text: string, to: string | null, target: React.HTMLAttributeAnchorTarget = "_blank") => (
        <HStack justifyContent={"flex-end"}>
            <Text>{text}</Text>
            {to && (
                <Link to={to} target={target}>
                    <Icon name={"generic.link"} fill={ThemeColor.ACTION_PRIMARY} w={"2rem"} h={"2rem"} />
                </Link>
            )}
        </HStack>
    );

    return (
        <Card py={"1.6rem"} {...props}>
            <TableContainer>
                <Table variant={"info"}>
                    <Tbody>
                        <Tr>
                            <Td>Contract</Td>
                            <Td>
                                {renderExtra(
                                    formatAddress(strategies[strategy].address.toUpperCase()),
                                    getContractHref(strategies[strategy]),
                                )}
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Card>
    );
};
