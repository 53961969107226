import { Maybe } from "true-myth";
import { Blockchain, blockchainsConfig, ChainEnvironment, ChainId } from "./blockchains.config";
import { Strategy } from "./contracts";
import { bigDecimal, BigDecimal } from "./utils/bigDecimal";
import { readChainEnvironment, readEnvVar, UnsupportedChainEnvError } from "./utils/env";

const strategyDisplayNames: Record<Strategy, string> = {
    [Strategy.MetaMorpho]: "Morpho USDC",
    [Strategy.MetaMorphoTBTC]: "Morpho TBTC",
};

export enum EnvVar {
    ChainEnvironment = "CHAIN_ENV",
    StrategyAddress = "ADDRESS",
    DepositTokenAddress = "DEPOSIT_TOKEN_ADDRESS",
    MinDepositAmount = "MIN_DEPOSIT_AMOUNT",
    MaxDepositAmount = "MAX_DEPOSIT_AMOUNT",
    SupplyQueue = "SUPPLY_QUEUE",
}

interface ChainConfig {
    blockchain: Blockchain;
    environment: ChainEnvironment;
    id: ChainId;
    explorerUrl?: string;
}

export interface StrategyProperties {
    strategy: Strategy;
    name: string;
    chain: ChainConfig;
    address: string;
    depositTokenAddress: string | null;
    minDepositAmount: BigDecimal | null;
    maxDepositAmount: BigDecimal | null;
    supplyQueue: string[];
}

export type StrategiesConfig = Record<Strategy, StrategyProperties>;

const strategyBlockchainMapping: Record<Strategy, Blockchain> = {
    [Strategy.MetaMorpho]: Blockchain.Ethereum,
    [Strategy.MetaMorphoTBTC]: Blockchain.Ethereum,
};

const readChainConfig = (strategy: Strategy): ChainConfig => {
    const blockchain = strategyBlockchainMapping[strategy];
    const environment = readChainEnvironment(strategy);
    const { id, explorerUrl } = blockchainsConfig[blockchain][environment] ?? {};
    if (id === undefined) throw UnsupportedChainEnvError(strategy, blockchain, environment);

    return {
        environment,
        id,
        blockchain,
        explorerUrl,
    };
};

const readStrategyProperties = (strategy: Strategy): StrategyProperties => ({
    strategy,
    name: strategyDisplayNames[strategy],
    address: readEnvVar(strategy, EnvVar.StrategyAddress),
    chain: readChainConfig(strategy),
    depositTokenAddress: readEnvVar(strategy, EnvVar.DepositTokenAddress, false),
    minDepositAmount: Maybe.of(readEnvVar(strategy, EnvVar.MinDepositAmount, false)).mapOr(null, bigDecimal),
    maxDepositAmount: Maybe.of(readEnvVar(strategy, EnvVar.MaxDepositAmount, false)).mapOr(null, bigDecimal),
    supplyQueue: readEnvVar(strategy, EnvVar.SupplyQueue, true).split(","),
});

export const strategiesConfig: StrategiesConfig = {
    [Strategy.MetaMorpho]: readStrategyProperties(Strategy.MetaMorpho),
    [Strategy.MetaMorphoTBTC]: readStrategyProperties(Strategy.MetaMorphoTBTC),
};
