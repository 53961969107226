import { Token, Strategy, StrategyAbi, AccountBalance, StrategyClient } from "../types";
import { BigDecimal, BIG_MAX_UINT256 } from "../../utils/bigDecimal";
import { tokenUnitConverter, TokenUnitConverter } from "../../utils/tokenUnitConverter";
import { promiseFromResult } from "../../utils/data";
import { Erc20TokenAbi } from '../generated-types/Erc20TokenAbi';
import { Maybe } from "true-myth";
import { ContractTransaction } from "ethers";

export class MetaMorphoClient<T extends Strategy.MetaMorpho>
    implements StrategyClient<T> {
    protected depositTokenUnitConverter: Maybe<TokenUnitConverter>;
    protected iouTokenUnitConverter: Maybe<TokenUnitConverter>;
    protected iouTokenWithdrawalUnitConverter: Maybe<TokenUnitConverter>;

    constructor(
        public contract: StrategyAbi<T>,
        protected depositTokenContract: Erc20TokenAbi,
        protected contractAddress: string,
        protected supplyQueue: string[],
    ) {
        this.depositTokenUnitConverter = Maybe.nothing();
        this.iouTokenUnitConverter = Maybe.nothing();
        this.iouTokenWithdrawalUnitConverter = Maybe.nothing();
        this.getIOUTokenUnitConverter.bind(this)
        this.getIOUTokenWithdrawalUnitConverter.bind(this)
        this.getDepositTokenUnitConverter.bind(this);
        this.supplyQueue = supplyQueue;
    }

    protected getIOUTokenUnitConverter = async (): Promise<TokenUnitConverter> =>
        this.iouTokenUnitConverter.match({
            Nothing: async () => {
                const iouToken = await this.getIOUToken();
                const converter = tokenUnitConverter(iouToken.decimals);
                this.iouTokenUnitConverter = Maybe.just(converter);
                return converter;
            },
            Just: converter => Promise.resolve(converter),
        });

    // Withdrawal is with 6 decimals
    protected getIOUTokenWithdrawalUnitConverter = async (): Promise<TokenUnitConverter> =>
        this.iouTokenUnitConverter.match({
            Nothing: async () => {
                const token = await this.getDepositToken()
                const converter = tokenUnitConverter(token.decimals);
                this.iouTokenUnitConverter = Maybe.just(converter);
                return converter;
            },
            Just: converter => Promise.resolve(converter),
        });

    protected getDepositTokenUnitConverter = async (): Promise<TokenUnitConverter> =>
        this.depositTokenUnitConverter.match({
            Nothing: async () => {
                const depositToken = await this.getDepositToken();
                const converter = tokenUnitConverter(depositToken.decimals);
                this.depositTokenUnitConverter = Maybe.just(converter);
                return converter;
            },
            Just: converter => Promise.resolve(converter),
        });

    getDepositToken = async (): Promise<Token> => {
        const symbol = await this.depositTokenContract.symbol();
        const decimals = await this.depositTokenContract.decimals();

        return { symbol, decimals };
    };

    getIOUToken = async (): Promise<Token> => {
        const symbol = await this.contract.symbol();
        const decimals = await this.contract.decimals();

        return { symbol, decimals };
    };

    getTotalBalance = async (): Promise<BigDecimal> => {
        const totalBalance = await this.contract.totalAssets();
        const converter = await this.getDepositTokenUnitConverter();

        return promiseFromResult(converter.parse(totalBalance));
    };

    getAllowance = async (account: string): Promise<BigDecimal> => {
        const allowance = await this.depositTokenContract.allowance(account, this.contractAddress ?? "");
        const converter = await this.getDepositTokenUnitConverter();

        return promiseFromResult(converter.parse(allowance));
    };

    getAccountBalance = async (account: string): Promise<AccountBalance> => {
        const balance = await this.contract.balanceOf(account);
        const converter = await this.getIOUTokenUnitConverter();

        return promiseFromResult(converter.parse(balance).map<AccountBalance>(balance => ({
            shares: balance,
            amount: balance
        })));
    };

    getDepositTokenBalance = async (account: string): Promise<BigDecimal> => {
        const balance = await this.depositTokenContract.balanceOf(account);
        const converter = await this.getDepositTokenUnitConverter();

        return promiseFromResult(converter.parse(balance));
    };

    approveSpend = async (account: string, amount?: BigDecimal): Promise<ContractTransaction> => {
        const converter = await this.getDepositTokenUnitConverter();
        const approveAmount = amount ? await promiseFromResult(converter.format(amount)) : BIG_MAX_UINT256.toFixed();

        return this.depositTokenContract.approve(this.contractAddress ?? "", approveAmount, {
            from: account,
        });
    };

    deposit = async (account: string, amount: BigDecimal, signature?: string): Promise<ContractTransaction> => {
        const converter = await this.getDepositTokenUnitConverter();
        const depositAmount = await promiseFromResult(converter.format(amount));

        const openQueueData = this.contract.interface.encodeFunctionData("setSupplyQueue", [this.supplyQueue])
        const depositData = this.contract.interface.encodeFunctionData("deposit", [depositAmount, account])
        const closeQueueData = this.contract.interface.encodeFunctionData("setSupplyQueue", [[]])

        const multicallData = [openQueueData, depositData, closeQueueData]

        return this.contract.multicall(multicallData, { from: account });
    };

    withdraw = async (account: string, amount: BigDecimal): Promise<ContractTransaction> => {
        const converter = await this.getIOUTokenWithdrawalUnitConverter();
        const withdrawAmount = await promiseFromResult(converter.format(amount));

        return this.contract.withdraw(withdrawAmount, account, account, { from: account });
    };
}
