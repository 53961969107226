import { ReactComponent as MORPHO } from "./assets/crypto/MORPHO.svg";
import { ReactComponent as USDC } from "./assets/crypto/USDC.svg";
import { ReactComponent as TBTC } from "./assets/crypto/TBTC.svg";

export const crypto = {
    "crypto.usdc": USDC,
    "crypto.sbmorphousdc": MORPHO,
    "crypto.tbtc": TBTC,
    "crypto.sbmorphotbtc": MORPHO,
};
