import React from "react";
import { MorphoResponse, MorphoApiService } from "../../rest/morpho";
import { Strategy } from "../types";
import { AppConfigContext } from "../../context";

export const useApyData = (strategy: Strategy) => {
    const morphoApiService = new MorphoApiService();
    const { strategies } = React.useContext(AppConfigContext);
    const [apyData, setApyData] = React.useState<MorphoResponse>();

    const fetchApyData = async () => {
        try {
            setApyData(await morphoApiService.getApy(strategies[strategy].address));
        } catch (e) {
            console.error(`Failed to fetch allocation data: ${e}`);
        }
    };

    React.useEffect(() => {
        fetchApyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strategy]);

    return { apyData, fetchApyData };
};